@font-face {
  font-family: 'Alexandria-Regular';
  src: url('../fonts/Alexandria-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Alexandria-Bold';
  src: url('../fonts/Alexandria-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Alexandria-Light';
  src: url('../fonts/Alexandria-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}

$bold-font: 'Alexandria-Bold';
$regular-font: 'Alexandria-Regular';
$light-font: 'Alexandria-Light';
