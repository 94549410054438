@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'assets/styles/fonts.scss';

html {
  scroll-behavior: smooth;
}

body {
  @apply bg-white;
  font-family: $regular-font !important;
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: hidden;
}

@layer utilities {
  .text-gradient {
    background: linear-gradient(
      260deg,
      #f9a533 0.76%,
      #e1344e 25.05%,
      #202f55 49.33%,
      #3479bc 70.22%,
      #a24994 97.9%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .aos-animate.text-gradient {
    opacity: 0.1 !important;
  }
}

/* Containers : landing-page-container */
.section-container {
  @apply w-full lg:max-w-7xl h-full lg:mt-40 flex flex-col items-center justify-center max-md:mt-14 max-sm:px-3 bg-sections-pattern bg-left-top bg-no-repeat bg-auto;
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
